import React from "react"

import Header from "../components/HeaderSub"
import GameFrame from "../components/GameFrame"
import NwHelmet from "../components/NwHelmet"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import hvLogo from "../images/hildingvilding_spion.svg"

export default function GameTemplate({
  pageContext: { lang, pagePath, title, gamePath, showCloseButton = false },
}) {
  return (
    <div>
      {!showCloseButton &&
        <Header
          lang={lang}
          title={title}
          room={6}
          roomSymbol={hvLogo}
        />
      }
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{
          url: `/assets/share-images/NW_Delning8_HV_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
      />
      <GameFrame lang={lang} title={title} path={gamePath} showCloseButton={showCloseButton} />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </div>
  )
}
