import React, { useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import useReducedMotion from "../hooks/reducedMotion"
import { useStore } from "../hooks/store"
import getRoomLink from "../utils/get-room-link"

import CloseIcon from "./icons/Close"
import closeIcon from "../images/close-plaster.svg"

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.hasHeader ? "3rem" : 0};
  left: 0;
  width: 100%;
  height: ${props => props.hasHeader ? "calc(100% - 3rem)" : "100%"};
  background: white;
`

const CloseButton = styled(Link)`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  z-index: 2;
  display: inline-block;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);

  img {
    display: block;
    width: auto;
    height: 100%;
  }
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export default function GameFrame({ lang, title, path, showCloseButton = false, ...props }) {
  const intl = useIntl()
  const [store] = useStore()
  const shouldReduceMotion = useReducedMotion()
  let interval

  useEffect(() => {
    interval = setInterval(() => {
      const bodyContent = document.querySelector('iframe').contentWindow.document?.body?.innerHTML

      if (bodyContent) {
        const frameBody = document.querySelector('iframe').contentWindow.document.body
        const noAnimation = shouldReduceMotion || !store.animationsEnabled
        frameBody.className = noAnimation ? "no-animation" : ""
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [shouldReduceMotion, store.animationsEnabled])

  return (
    <Wrapper hasHeader={!showCloseButton} {...props}>
      {showCloseButton &&
        <CloseButton to={getRoomLink(6, lang)} className="close-button">
          <img
            src={closeIcon}
            alt={`${intl.formatMessage({ id: "backTo" })} ${intl.formatMessage({ id: "roomHeading6" })}`}
          />
        </CloseButton>
      }
      <Iframe src={path} title={title} />
    </Wrapper>
  )
}
